import React, { useContext, useEffect, useLayoutEffect, useState } from 'react'
import { getRestaurant, getRestaurantAdmin, getRestaurantOwner } from '../../Processing/Database'
import { UserContext } from '../../App';


export default function HomePage() {

 

  

  return (
    <div></div>
  )
}
